import React, { useState, useCallback, useEffect } from "react";
import { Container, Form, Input, FormGroup, Label, Button, FormText } from "reactstrap";
import JQuery from "jquery";

export default function App() {
    const [lastLoginFailed, setLastLoginFailed] = useState(false);

    const formSubmit = useCallback(
        async e => {
            e.preventDefault();
            try {
                var x = await JQuery.ajax({
                    url: "/auth/login",
                    type: "POST",
                    data: JQuery(e.target).serialize(),
                });
                console.log(x);
            } catch (error) {
                if (error.status == 401) {
                    setLastLoginFailed(true);
                    return;
                }
                throw error;
            }
        },
        [setLastLoginFailed]
    );

    useEffect(() => {
        if (window.location.pathname === "/login/error") setLastLoginFailed(true);
    }, [setLastLoginFailed]);

    return (
        <Container className="text-center">
            <h1>Авторизация</h1>
            <Form action="/auth/login" method="post" className="mx-auto" style={{ maxWidth: 300 }}>
                <FormGroup className="text-left">
                    <Label for="username">Имя:</Label>
                    <Input id="username" name="username" defaultValue="admin" required />
                </FormGroup>
                <FormGroup className="text-left">
                    <Label for="password">Пароль:</Label>
                    <Input type="password" id="password" name="password" required />
                </FormGroup>
                {lastLoginFailed && (
                    <FormText color="danger" className="my-2">
                        Неверное имя или пароль
                    </FormText>
                )}
                <Button type="submit" color="primary">
                    Авторизоваться
                </Button>
            </Form>
        </Container>
    );
}
